/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ArticleDto } from '../models';
import { CreateArticleRevisionDto } from '../models';
import { GetArticlesResponseDto } from '../models';
import { PartialUpdateArticlePayloadDto } from '../models';
import { UpdateArticlePayloadDto } from '../models';
import { UploadImageResponseDto } from '../models';

/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Требуются права администратора
     * @summary Архивировать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerArchiveArticleRevision: async (articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerArchiveArticleRevision.');
      }
      const localVarPath = `/api/2.0/news/articles/{article_id}/archive`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Создать новостную статью
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerCreateArticle: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/news/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Создать редакцию статьи
     * @param {CreateArticleRevisionDto} body
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerCreateArticleRevision: async (body: CreateArticleRevisionDto, articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling newsControllerCreateArticleRevision.');
      }
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerCreateArticleRevision.');
      }
      const localVarPath = `/api/2.0/news/articles/{article_id}/revision`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Получить список новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindArticles: async (totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/news/articles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (totalCountRequired !== undefined) {
        localVarQueryParameter['total_count_required'] = totalCountRequired;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Получить новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindOneArticle: async (articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerFindOneArticle.');
      }
      const localVarPath = `/api/2.0/news/articles/{article_id}`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Необязательная авторизация
     * @summary Получить опубликованную новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindOnePublishedArticle: async (articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerFindOnePublishedArticle.');
      }
      const localVarPath = `/api/2.0/news/articles/published/{article_id}`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Необязательная авторизация
     * @summary Получить список опубликованных новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindPublishedArticles: async (totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/news/articles/published`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (totalCountRequired !== undefined) {
        localVarQueryParameter['total_count_required'] = totalCountRequired;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sort_order'] = sortOrder;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] = start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] = end;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (sortBy !== undefined) {
        localVarQueryParameter['sort_by'] = sortBy;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи частично
     * @param {PartialUpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerPartialUpdateArticle: async (body: PartialUpdateArticlePayloadDto, revisionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling newsControllerPartialUpdateArticle.');
      }
      // verify required parameter 'revisionId' is not null or undefined
      if (revisionId === null || revisionId === undefined) {
        throw new RequiredError('revisionId', 'Required parameter revisionId was null or undefined when calling newsControllerPartialUpdateArticle.');
      }
      const localVarPath = `/api/2.0/news/articles/revisions/{revision_id}`
        .replace(`{${'revision_id'}}`, encodeURIComponent(String(revisionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Опубликовать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerPublishArticleRevision: async (articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerPublishArticleRevision.');
      }
      const localVarPath = `/api/2.0/news/articles/{article_id}/publish`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи полностью
     * @param {UpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerUpdateArticle: async (body: UpdateArticlePayloadDto, revisionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling newsControllerUpdateArticle.');
      }
      // verify required parameter 'revisionId' is not null or undefined
      if (revisionId === null || revisionId === undefined) {
        throw new RequiredError('revisionId', 'Required parameter revisionId was null or undefined when calling newsControllerUpdateArticle.');
      }
      const localVarPath = `/api/2.0/news/articles/revisions/{revision_id}`
        .replace(`{${'revision_id'}}`, encodeURIComponent(String(revisionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     * Требуются права администратора
     * @summary Загрузить основное изображение статьи
     * @param {string} image
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerUploadImage: async (image: string, articleId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling newsControllerUploadImage.');
      }
      // verify required parameter 'articleId' is not null or undefined
      if (articleId === null || articleId === undefined) {
        throw new RequiredError('articleId', 'Required parameter articleId was null or undefined when calling newsControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/news/articles/{article_id}/image`
        .replace(`{${'article_id'}}`, encodeURIComponent(String(articleId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Требуются права администратора
     * @summary Архивировать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerArchiveArticleRevision(articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerArchiveArticleRevision(articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Создать новостную статью
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerCreateArticle(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerCreateArticle(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Создать редакцию статьи
     * @param {CreateArticleRevisionDto} body
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerCreateArticleRevision(body: CreateArticleRevisionDto, articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerCreateArticleRevision(body, articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Получить список новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerFindArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArticlesResponseDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerFindArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Получить новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerFindOneArticle(articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerFindOneArticle(articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Необязательная авторизация
     * @summary Получить опубликованную новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerFindOnePublishedArticle(articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerFindOnePublishedArticle(articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Необязательная авторизация
     * @summary Получить список опубликованных новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerFindPublishedArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArticlesResponseDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerFindPublishedArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи частично
     * @param {PartialUpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerPartialUpdateArticle(body: PartialUpdateArticlePayloadDto, revisionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerPartialUpdateArticle(body, revisionId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Опубликовать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerPublishArticleRevision(articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerPublishArticleRevision(articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи полностью
     * @param {UpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerUpdateArticle(body: UpdateArticlePayloadDto, revisionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerUpdateArticle(body, revisionId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     * Требуются права администратора
     * @summary Загрузить основное изображение статьи
     * @param {string} image
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async newsControllerUploadImage(image: string, articleId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await NewsApiAxiosParamCreator(configuration)
        .newsControllerUploadImage(image, articleId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Требуются права администратора
     * @summary Архивировать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerArchiveArticleRevision(articleId: number, options?: any): AxiosPromise<void> {
      return NewsApiFp(configuration)
        .newsControllerArchiveArticleRevision(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Создать новостную статью
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerCreateArticle(options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerCreateArticle(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Создать редакцию статьи
     * @param {CreateArticleRevisionDto} body
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerCreateArticleRevision(body: CreateArticleRevisionDto, articleId: number, options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerCreateArticleRevision(body, articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Получить список новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any): AxiosPromise<GetArticlesResponseDto> {
      return NewsApiFp(configuration)
        .newsControllerFindArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Получить новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindOneArticle(articleId: number, options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerFindOneArticle(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Необязательная авторизация
     * @summary Получить опубликованную новостную статью по ID
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindOnePublishedArticle(articleId: number, options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerFindOnePublishedArticle(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Необязательная авторизация
     * @summary Получить список опубликованных новостных статей
     * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
     * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
     * @param {number} [pageSize] Количество записей, возвращаемых в ответе
     * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
     * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
     * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
     * @param {string} [status] Жизненный цикл статьи
     * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerFindPublishedArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any): AxiosPromise<GetArticlesResponseDto> {
      return NewsApiFp(configuration)
        .newsControllerFindPublishedArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи частично
     * @param {PartialUpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerPartialUpdateArticle(body: PartialUpdateArticlePayloadDto, revisionId: number, options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerPartialUpdateArticle(body, revisionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Опубликовать статью
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerPublishArticleRevision(articleId: number, options?: any): AxiosPromise<void> {
      return NewsApiFp(configuration)
        .newsControllerPublishArticleRevision(articleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Обновить черновик новостной статьи полностью
     * @param {UpdateArticlePayloadDto} body
     * @param {number} revisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerUpdateArticle(body: UpdateArticlePayloadDto, revisionId: number, options?: any): AxiosPromise<ArticleDto> {
      return NewsApiFp(configuration)
        .newsControllerUpdateArticle(body, revisionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Требуются права администратора
     * @summary Загрузить основное изображение статьи
     * @param {string} image
     * @param {number} articleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    newsControllerUploadImage(image: string, articleId: number, options?: any): AxiosPromise<UploadImageResponseDto> {
      return NewsApiFp(configuration)
        .newsControllerUploadImage(image, articleId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
  /**
   * Требуются права администратора
   * @summary Архивировать статью
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerArchiveArticleRevision(articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerArchiveArticleRevision(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Создать новостную статью
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerCreateArticle(options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerCreateArticle(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Создать редакцию статьи
   * @param {CreateArticleRevisionDto} body
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerCreateArticleRevision(body: CreateArticleRevisionDto, articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerCreateArticleRevision(body, articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Получить список новостных статей
   * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
   * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
   * @param {number} [pageSize] Количество записей, возвращаемых в ответе
   * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [status] Жизненный цикл статьи
   * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerFindArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerFindArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Получить новостную статью по ID
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerFindOneArticle(articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerFindOneArticle(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Необязательная авторизация
   * @summary Получить опубликованную новостную статью по ID
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerFindOnePublishedArticle(articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerFindOnePublishedArticle(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Необязательная авторизация
   * @summary Получить список опубликованных новостных статей
   * @param {boolean} [totalCountRequired] Указывает, следует ли отображать общее количество записей в ответе
   * @param {number} [page] Номер страницы, указывающий, какой набор записей будет возвращен в ответе
   * @param {number} [pageSize] Количество записей, возвращаемых в ответе
   * @param {string} [sortOrder] Сортировка записей в ответе в порядке возрастания или убывания.
   * @param {string} [start] Дата и время начала диапазона, отображаемого в ответе.
   * @param {string} [end] Дата и время окончания диапазона, отображаемого в ответе.
   * @param {string} [status] Жизненный цикл статьи
   * @param {string} [sortBy] Сортировка статей в ответе по указанному значению, например времени создания, времени изменения или времени публикации
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerFindPublishedArticles(totalCountRequired?: boolean, page?: number, pageSize?: number, sortOrder?: string, start?: string, end?: string, status?: string, sortBy?: string, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerFindPublishedArticles(totalCountRequired, page, pageSize, sortOrder, start, end, status, sortBy, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Обновить черновик новостной статьи частично
   * @param {PartialUpdateArticlePayloadDto} body
   * @param {number} revisionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerPartialUpdateArticle(body: PartialUpdateArticlePayloadDto, revisionId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerPartialUpdateArticle(body, revisionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Опубликовать статью
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerPublishArticleRevision(articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerPublishArticleRevision(articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Обновить черновик новостной статьи полностью
   * @param {UpdateArticlePayloadDto} body
   * @param {number} revisionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerUpdateArticle(body: UpdateArticlePayloadDto, revisionId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerUpdateArticle(body, revisionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Требуются права администратора
   * @summary Загрузить основное изображение статьи
   * @param {string} image
   * @param {number} articleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public newsControllerUploadImage(image: string, articleId: number, options?: any) {
    return NewsApiFp(this.configuration)
      .newsControllerUploadImage(image, articleId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
